body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-green
{
  
  background-color: #f7ecec !important;
  color: #000;
}
.bg-green a
{
  color: #000  !important;
}
.mr-20
{
  margin-top: 40px;
}

.mrpayment-200
{
  margin-top: 120px !important;
}

.paymentbody
{
  margin: 0 auto;
  width: 80% !important;
}

.mr-200
{
  margin-top: 300px;
}
.mr-bottom-100
{
  margin-bottom: 300px;
}
.footer-text
{
  line-height: 50px;
  color: #000;
}